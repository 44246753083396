// import ApiService from '@/core/services/api.service';
import { toSafeInteger, isArray } from "lodash";

// action types

// mutation types
export const ERROR = "error";
export const MESSAGE = "message";
export const SET_ERROR = "setError";
export const SET_MESSAGE = "setMessage";

const state = {
	errors: [],
	messages: [],
};

const getters = {
	errors(state) {
		return state.errors;
	},
	messages(state) {
		return state.messages;
	},
};

const actions = {
	[ERROR](context, param) {
		context.commit(SET_ERROR, param);
	},
	[MESSAGE](context, param) {
		context.commit(SET_MESSAGE, param);
	},
};

const mutations = {
	[SET_ERROR](state, error) {
		if (error && isArray(error)) {
			state.errors = error.map((row) => {
				row.timeout = toSafeInteger(process.env.VUE_APP_MESSAGE_TIMEOUT);
				return row;
			});
		} else {
			state.errors = [];
		}
	},
	[SET_MESSAGE](state, message) {
		if (message && isArray(message)) {
			state.messages = message.map((row) => {
				row.timeout = toSafeInteger(process.env.VUE_APP_MESSAGE_TIMEOUT);
				return row;
			});
		} else {
			state.messages = [];
		}
	},
};

export default {
	state,
	actions,
	mutations,
	getters,
};
