import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
import { SET_LOCAL_DB, CART_COUNT } from "@/core/services/store/config.module";
// import { VERIFY_AUTH } from '@/core/services/store/auth.module';
import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";
import MainSetting from "@/core/mixins/main.mixin";
import VueMask from "v-mask";
import PortalVue from "portal-vue";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import JwtService from "@/core/services/jwt.service";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

Vue.use(VueMask);

Vue.use(PortalVue);

Vue.use(CKEditor);

Vue.use(MainSetting);

Vue.use(VueSweetalert2);

Vue.config.productionTip = false;

Vue.prototype.$APPNAME = process.env.VUE_APP_NAME;
Vue.prototype.$messageTimeout = process.env.VUE_APP_MESSAGE_TIMEOUT;
Vue.prototype.$uploadLIMIT = process.env.VUE_APP_FILE_UPLOAD_LIMIT;
Vue.prototype.$uploadSizeLIMIT = process.env.VUE_APP_FILE_SIZE_LIMIT;
Vue.prototype.$whLimit = process.env.VUE_APP_WAREHOUSE_LIMIT;
Vue.prototype.$whlLimit = process.env.VUE_APP_WHLOCATION_LIMIT;
Vue.prototype.$grpAttrLimit = process.env.VUE_APP_ITEM_GROUP_ATTR_LIMIT;
Vue.prototype.$timezone = process.env.VUE_APP_TIMEZONE;

Vue.prototype.$assetURL = (param) => {
	return process.env.VUE_APP_BASE_URL + param;
};

Vue.prototype.$apiURL = () => {
	return process.env.VUE_APP_API_URL;
};

// Global 3rd party plugins
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;

// Vue 3rd party plugins
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";

// API service init
ApiService.init();

store.dispatch(SET_LOCAL_DB);
if (JwtService.isAuthenticated()) {
	store.dispatch(CART_COUNT);
}

router.beforeEach((to, from, next) => {
	// Ensure we checked auth before each page load.
	// Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);
	next();
	// reset config to initial state
	store.dispatch(RESET_LAYOUT_CONFIG);

	// Scroll page to top on every route change
	setTimeout(() => {
		window.scrollTo(0, 0);
	}, 100);
});

new Vue({
	router,
	store,
	vuetify,
	render: (h) => h(App),
}).$mount("#app");
