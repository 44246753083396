import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import router from "@/router";
import JwtService from "@/core/services/jwt.service";
import { validationError } from "@/core/lib/common.lib";
import { ErrorEventBus } from "@/core/lib/message.lib";

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
	init() {
		Vue.use(VueAxios, axios);
		Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
		Vue.axios.defaults.headers.common["Accept"] = "application/json";
		Vue.axios.defaults.trailingSlash = false;

		// Add a request interceptor
		Vue.axios.interceptors.request.use(
			function (config) {
				if (config.url.endsWith("/")) {
					config.url = config.url.slice(0, -1);
				}
				return config;
			},
			function (error) {
				return Promise.reject(error);
			}
		);

		// Add a response interceptor
		Vue.axios.interceptors.response.use(
			function (response) {
				return response;
			},
			function (error) {
				const response = error.response;

				if (response.status == 401) {
					window.localStorage.clear();
					router.push({ name: "login" });
					return Promise.reject(error);
				}
				if (response.status == 422) {
					const errors = validationError(response);
					ErrorEventBus.$emit("set-error", errors);
				} else {
					if (response.data && response.data.message) {
						ErrorEventBus.$emit("set-error", [{ model: true, message: response.data.message }]);
					} else {
						ErrorEventBus.$emit("set-error", [
							{ model: true, message: "Something went wrong. Please try again later." },
						]);
					}
				}
				return Promise.reject(error);
			}
		);
	},

	/**
	 * Set the default HTTP request headers
	 */
	setHeader() {
		Vue.axios.defaults.headers.common["Authorization"] = `bearer ${JwtService.getToken()}`;
	},

	setCustomHeader(token) {
		Vue.axios.defaults.headers.common["Authorization"] = `bearer ${token}`;
	},

	query(resource, params) {
		return new Promise((resolve, reject) => {
			Vue.axios
				.get(resource, { params: params })
				.then(({ data }) => {
					resolve(data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},

	/**
	 * Send the GET HTTP request
	 * @param resource
	 * @param slug
	 * @returns {*}
	 */
	get(resource, slug = "") {
		return new Promise((resolve, reject) => {
			Vue.axios
				.get(`${resource}/${slug}`)
				.then(({ data }) => {
					resolve(data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},

	/**
	 * Set the POST HTTP request
	 * @param resource
	 * @param params
	 * @returns {*}
	 */
	post(resource, params) {
		return new Promise((resolve, reject) => {
			Vue.axios
				.post(resource, params)
				.then(({ data }) => {
					resolve(data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},

	/**
	 * Send the UPDATE HTTP request
	 * @param resource
	 * @param slug
	 * @param params
	 * @returns {IDBRequest<IDBValidKey> | Promise<void>}
	 */
	update(resource, slug, params) {
		return new Promise((resolve, reject) => {
			Vue.axios
				.put(`${resource}/${slug}`, params)
				.then(({ data }) => {
					resolve(data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},

	/**
	 * Send the PUT HTTP request
	 * @param resource
	 * @param params
	 * @returns {IDBRequest<IDBValidKey> | Promise<void>}
	 */
	put(resource, params) {
		return new Promise((resolve, reject) => {
			Vue.axios
				.put(resource, params)
				.then(({ data }) => {
					resolve(data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},

	/**
	 * Send the PATCH HTTP request
	 * @param resource
	 * @param params
	 * @returns {IDBRequest<IDBValidKey> | Promise<void>}
	 */
	patch(resource, params) {
		return new Promise((resolve, reject) => {
			Vue.axios
				.patch(resource, params)
				.then(({ data }) => {
					resolve(data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},

	/**
	 * Send the DELETE HTTP request
	 * @param resource
	 * @returns {*}
	 */
	delete(resource) {
		return new Promise((resolve, reject) => {
			Vue.axios
				.delete(resource)
				.then(({ data }) => {
					resolve(data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},

	/**
	 * Send the UPLOAD HTTP request
	 * @param resource
	 * @returns {*}
	 */
	upload(resource, params) {
		return new Promise((resolve, reject) => {
			Vue.axios
				.post(resource, params, {
					headers: { "content-type": "multipart/form-data" },
				})
				.then(({ data }) => {
					resolve(data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
};

export default ApiService;
